import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 135vh;
    overflow: hidden;
    padding: 0px;
    box-sizing: border-box;
`;

const PdfWrapper = styled.div`
    width: 70%;
    height: 80vh;
    overflow-y: auto;
    padding: 0px;
    border-radius: 0px;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const Whitepaper = () => {
  return (
    <Container>
      <PdfWrapper>
        <Iframe src="https://iaai.meme/iaai-whitepaper.pdf" />
      </PdfWrapper>
    </Container>
  );
};

export default Whitepaper;
