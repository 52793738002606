import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Nav = styled.nav`
  background: rgba(255, 255, 255, 0.8);
  padding: 1rem;
  position: fixed;
  width: 100%;
  top: 120px;
  z-index: 1000;
`;

const Menu = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
`;

const MenuItem = styled.li`
  a {
    text-decoration: none;
    color: #333;
    font-weight: bold;

    &:hover {
      color: #007bff;
    }
  }
`;

const Header = () => (
  <Nav>
    <Menu>
      <MenuItem>
        <Link to="/">Chat with IA</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/whitepaper">Whitepaper</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/roadmap">Roadmap</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/tokenomics">Tokenomics</Link>
      </MenuItem>
    </Menu>
  </Nav>
);

export default Header;