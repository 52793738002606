import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 135vh;
  overflow: hidden;
  padding: 0px;
  box-sizing: border-box;
`;

const ContentWrapper = styled.div`
    width: 70%;
    height: 80vh;
    overflow-y: auto;
    background: rgba(255, 255, 255, 0.8);
    padding: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;

function Roadmap() {
  return (
    <Container style={{ fontFamily: 'Roboto, sans-serif' }}>
    <ContentWrapper>
    <div>
    <h1 style={{ color: '#9c53e1' }}>Roadmap</h1>
    <h3>DONE: Creation of the IAAI MEME as a driver for development and network support.</h3>
    <h3>DONE: Listing of the IAAI ERC-20 token</h3>
    <h3>TODO: Development of the IAAI.meme ecosystem with key goals:</h3>
    <ul>
        <li>Community</li>
        <li>Exchanges</li>
        <li>Marketing</li>
        <li>Apps</li>
        <li>NFTs</li>
    </ul>
    <h3>TODO: Creation of a smart contract ecosystem as an API for IAAI</h3>
    <h3>TODO: Development and training of the main AI for decision-making and participation in the DAO</h3>
    <h3>TODO: Development and training of applied AIs for IAAI.digital projects</h3>
    <h3>TODO: Creation of a Blockchain-driven AI project ecosystem IAAI.digital</h3>
    <h3>TODO: Creation of a venture investment ecosystem for IAAI.digital projects</h3>
    </div>
    </ContentWrapper>
  </Container>
  );
}

export default Roadmap;