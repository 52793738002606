import React, { useEffect, useState } from 'react';

const TypingAnimationMessage = ({ message }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [index, setIndex] = useState(0);

    const scrollToBottom = () => {
        const chatMessageContainer = document.querySelector('.react-chatbot-kit-chat-message-container');
        if (chatMessageContainer) {
            chatMessageContainer.scrollTop = chatMessageContainer.scrollHeight;
        }
    };

    useEffect(() => {
        if (index < message.length) {
            const timeoutId = setTimeout(() => {
                setDisplayedText(displayedText + message[index]);
                setIndex(index + 1);
                scrollToBottom();
            },3); // typing speed
            return () => clearTimeout(timeoutId);
        }
    }, [index, message, displayedText]);

    return <div>{displayedText}</div>;
};

export default TypingAnimationMessage;