import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 135vh;
  overflow: hidden;
  padding: 0px;
  box-sizing: border-box;
`;

const ContentWrapper = styled.div`
    width: 70%;
    height: 80vh;
    overflow-y: auto;
    background: rgba(255, 255, 255, 0.8);
    padding: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;

function Tokenomics() {
  return (
    <Container style={{ fontFamily: 'Roboto, sans-serif' }}>
    <ContentWrapper>
    <div>
      <h1 style={{ color: '#9c53e1' }}>Tokenomics</h1>
      <h3>IAAI ERC-20 token deployed with the following parameters:</h3>
    <ul>
        <li><strong>Token contract:</strong><a href="https://etherscan.io/address/0x7754c4a62e7d3cb682791af08afa4daf963de99a" target="_blank">https://etherscan.io/address/0x7754c4a62e7d3cb682791af08afa4daf963de99a</a></li>
        <li><strong>Total supply:</strong> 2,111,485,077,978,050 (Two quadrillion one hundred eleven trillion four hundred eighty-five billion seventy-seven million nine hundred seventy-eight thousand fifty)</li>
        <li><strong>Token contract source:</strong><a href="https://github.com/iaai-meme/IAAI_Token/blob/main/contracts/IntelligentAutonomousArtificialIntelligence.sol" target="_blank">IntelligentAutonomousArtificialIntelligence.sol</a></li>
        <li><strong>Token contract audit:</strong>In progress</li>
        <li><strong>Burnable:</strong> No</li>
        <li><strong>Mintable:</strong> No</li>
        <li><strong>Token sale start:</strong> 2024-06-12</li>
        <li><strong>Token sale end:</strong> 2024-07-21</li>
        <li><strong>Token sale price:</strong> 0.0000000001$</li>
    </ul>
    </div>
    </ContentWrapper>
  </Container>
  );
}

export default Tokenomics;