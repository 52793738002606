import React from 'react';
import styled from 'styled-components';
import logoImage from '../assets/iaai-logo.png';

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: rgba(8, 8, 8, 0.8);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1100;
`;

const Logo = styled.img`
  height: 80px; /* Настройте размер логотипа по своему усмотрению */
`;

const LogoHeader = () => (
  <LogoContainer>
    <Logo src={logoImage} alt="Logo" />
  </LogoContainer>
);

export default LogoHeader;
